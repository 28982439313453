
@media (max-width: 920px) {

    .body-content {
        margin-top: 0px !important;
    }
}

/* Navbar DropDown==== */

.navbar-nav .service-submenu a {
    color: #000 !important;
    font-size: 0.8em;
    padding: 0.3em 0 0.3em 0em;
    text-align: start;
    text-decoration: none;
}

.navbar-nav .service-submenu a:hover {
    color: #006628 !important;
}

.service-submenu h6 {
    color: #fff;
    /* border-bottom: 2px solid #006628; */
    padding: 0.5em 0;
    text-transform: uppercase;
    text-align: left;
    font-size: 1em;
    background-color: #475B52;
}

.service-submenu {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    padding: 15px;
    /* margin: auto; */
}

.service-submenu p {
    font-style: italic;
}

.service-menu {
    position: absolute;
    height: 0vh;
    top: 72px;
    left: 20%;
    transform: translateX(-20%);
    background:
        /* linear-gradient(rgba(2, 2, 2, 0), rgba(0, 0, 0, 0)), url(/src/assets/images/ddcover.png)*/
        #fff;
    background-position: bottom;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    display: flex;
    /* padding: 0.7em; */
    z-index: 1;
    /* justify-content: center; */
    transition: all 0.7s cubic-bezier(1, 0, 0, 1);
    visibility: hidden;
    opacity: 0;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.315);
}

.navbar ul li:hover .service-menu {
    visibility: visible;
    opacity: 1;
    height: 70vh;
}


.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: transparent;
    color: #475B52;
    /* padding: 10px 15px; */
    border: none;
}















#main {
    transition: margin-left .5s;
    padding: 16px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidebar {
        padding-top: 15px;
    }

    .sidebar a {
        font-size: 18px;
    }
}

.col-lg-3:hover {
    /* transform: scale(1.1); */
    z-index: 2;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.overlay:hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}

.overlay h3 {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.overlay h3:hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.overlay h3,
.overlay p {
    color: white;
    text-align: center;
}

.col-lg-3:hover .overlay {
    opacity: 1;
}

.col-lg-3:hover .overlay h3,
.col-lg-3:hover .overlay p {
    opacity: 1;
    transition-delay: 0.2s;
}

/* Desktop */
@media screen and (min-width: 990px) {
    .section1 {
        height: 50vh;
    }

    .HideInDesktop {
        display: none;
    }

    .ChooseDesktopheading {
        padding-left: 100px;
        color: #fff;
        margin-top: 50px;
    }
    .Choosemobileheading {
        padding-left: 100px;
        color: #fff;
        margin-top: 50px;
    }
    .ChooseDesktopcontent {
        padding-left: 100px;color: #fff; margin-top: 50px;
    }
    .Choosemobilecontent {
        padding-left: 100px;color: #fff; margin-top: 50px;
    }
    .dotcordinates1{
        margin-top: 145px; margin-left: 60px;
    }
    /* .dotcordinates12{
        margin-top: 195px; margin-left:270px;
    } */
    .dotcordinates2{
        margin-top: 200px; margin-left: 280px;
    }
    .choosesectionstyle{
        height: 65vh;
background-size: cover; background-position: center; transition: all 0.3s ease-in-out; position: relative;
    }
    .choosesectionbackground{
        height: 100vh;  justify-content: center; align-items: center;
    }
    .ChoosesectionHeading{
        text-align: left; padding-left: 20px; font-size: 25px;
    }
    .productsectionstyle{
        float: left;
        /* margin-top: 50px; */
        /* height: 65vh; */
        width: 20%;
    }
    .querysectionstyle{
        padding: 70px 70px 0px 70px;
    }
    .mapstyle{
        width:500px;
        height: 400px;
        border: 0;
    }
    .querysectionstyle1{
    float: left;
    }
    .CatalogueSectionStyle{
        background-image:url(../../../assets/Image/DownloadCatalogue.png); height: 45vh; background-position: center; background-size: cover;
    }
    .CatalogueSectionStyle1{
        padding-top: 150px;
    }
    .ShopSectionStyle{
        padding: 30px 20px 0px 20px;
        margin-bottom: 30px;
    }
    .ShopStyle{
        padding: 50px 0px 50px 0px;
    }
    .choosesectionbackground{
        padding: 0;
    }
    .hoverGuides{
        width: 100%; border-radius: 10px; margin-top: 30px;
    }
    .hoverGuides:hover{
        width: 105%; border-radius: 10px; margin-left: -10px;
    }
    .guidesection{
        background-color: #fff;  padding: 80px 40px 40px 60px;
    }
}

/* Mobile */
@media screen and (max-width: 990px) {
    .section1 {
        height: 31.5vh;
    }

    .HideInPhone {
        display: none;
    }

    .mobilecarousel {
        margin-top: -115px;
    }

    .mobilecarouselHeight {
        height: 35vh;
    }

    .ChooseDesktopheading {
        padding-left: 100px;
        color: #fff;
        margin-top: 50px;
    }
    .Choosemobileheading {
        text-align: center;
        color: #fff;
        margin-top: 50px;
    }
    .ChooseDesktopcontent {
        padding-left: 100px;color: #fff; margin-top: 50px;
    }
    .Choosemobilecontent {
        text-align: center;color: #fff; margin-top: 15px; padding: 20px;
    }
    .dotcordinates1{
        margin-top: 90px; margin-left: 60px;
    }
    .dotcordinates2{
        margin-top: 120px; margin-left: 120px;
    }
    .choosesectionstyle{
        height: 20vh;
        width: 28vw;
background-size: cover; background-position: center; transition: all 0.3s ease-in-out; position: relative;
    }
    .choosesectionbackground{
        height: auto; display: flex; justify-content: center; align-items: center;
    }
    .ChoosesectionHeading{
        text-align: left; text-align: center; font-size: 12px;
    }
    .productsectionstyle{
        float: left;
        padding: 50px;
    }
    .querysectionstyle{
        padding: 70px 0px 0px 0px;
    }
    .mapstyle{
        width:142%;
        height: 250px;
        border: 0;
    }
    .querysectionstyle1{
    float: left;
    width: 100%;
    }
    .CatalogueSectionStyle{
        background-image:url(../../../assets/Image/CatalogueBackgroud.png); height: 70vh; background-position: center; background-size: cover;
    margin-right: 0px;
    }
    .CatalogueSectionStyle1{
        padding-top: 0px;
    }
    .CatalogueSectionStyle2{
        width: 70%;
    }
    .ShopStyle{
        padding: 25px;
    }
    .formdesign{
        margin-top: -130px;
        margin-left: 20px;
    }
    .choosesectionbackground{
        padding: 0;
    }
    .hoverGuides{
        width: 100%; border-radius: 10px; margin-top: 30px;
    }
    .hoverGuides:hover{
        width: 105%; border-radius: 10px; margin-left: -10px;
    }
    .guidesection{
        background-color: #fff;  padding: 80px 20px 40px 40px;
    }
}
figure.effect-zoe figcaption {
top: auto;
bottom: 0;
padding: 1em;
height: 2.75em;
background: #475B52;
color: #fff;
-webkit-transition: -webkit-transform 0.35s;
transition: transform 0.35s;
-webkit-transform: translate3d(0,100%,0);
transform: translate3d(0,100%,0);
}

.zoomeffect:hover{
/* height: 20vh; */
width: 40vw;
margin-left: -5px;
}