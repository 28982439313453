.slider {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slides-container {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  min-width: 100%;
}

.navigation-arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.left-arrow,
.right-arrow {
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow:hover,
.right-arrow:hover {
  color: #555; /* Change arrow color on hover */
}
@media screen and (min-width: 990px) {
  .ExibhitionContent {
    text-align: center !important;
    float: left !important;
    padding: 20px 50px 20px 50px !important;
    background-size: cover !important;
    background-position: center !important;
  }
}

@media screen and (max-width: 990px) {
  .ExibhitionContent {
    text-align: justify !important;
    float: left !important;
    padding: 20px !important;
    background-size: cover !important;
    background-position: center !important;
  }
}
