.modal-new {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in-out;
  z-index: 100000000;
  overflow: auto;
}
.modal-main {
  position: fixed;
  border-radius: 10px;
  background: white;
  width: 50%;
  height: 100%;
  top: 50%;
  left: 30%;
  bottom: 0;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  position: relative;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 20px;
  color: #000;
  opacity: 0.7;
}

.close-icon:hover {
  opacity: 1;
}
.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 30px;
  color: #000;
  opacity: 0.7;
}

.close-icon:hover {
  opacity: 1;
}
