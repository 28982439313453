.productimage-area {
  /* position: relative;
    height:10vh;
    width:7vw;
    background: #333; */
  margin: 20px;
  position: relative;
  /* height: 10vh; */
  /* width: 7vw; */
  background: #333;
  /* padding: 20px; */
}
.productimage-area img {
  max-width: 100%;
  height: auto;
}
.productremove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  /* font: 700 21px/20px sans-serif; */
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.productremove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
  color: #fff;
}
.productremove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}

/* Product Combination CSS */

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
}
