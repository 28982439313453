.root12 {
  padding: 3rem 1.5rem;
  border-radius: 5px;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  zoom: 70%;
  height: 100vh;
}

.root122 {
  padding: 3rem 1.5rem;
  border-radius: 5px;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  zoom: 70%;
  height: 114vh;
}

.figure12 {
  display: flex;
}

.figure12 img {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  border: 1px solid #475b52;
  margin-right: 1.5rem;
}

.figure12 figcaption {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.figure12 figcaption h4 {
  font-size: 1.4rem;
  font-weight: 500;
}

.figure12 figcaption h6 {
  font-size: 1rem;
  font-weight: 300;
}

.figure12 figcaption h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.order-track {
  margin-top: 2rem;
  padding: 0 1rem;
  border-top: 1px dashed #2c3e50;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
}

.order-track-step {
  display: flex;
  height: 6rem;
}

.order-track-step:last-child {
  overflow: hidden;
  /* height: 4rem; */
}

.order-track-step:last-child .order-track-status span:last-of-type {
  display: none;
}

.order-track-status {
  margin-right: 1.5rem;
  position: relative;
}

.order-track-status-dot {
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: #475b52;
}

.order-track-status-line {
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 6rem;
  background: #475b52;
}

.order-track-text-stat {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 3px;
}

.order-track-text-sub {
  font-size: 1rem;
  font-weight: 300;
}

.order-track {
  transition: all 0.3s height 0.3s;
  transform-origin: top center;
}

.redrow {
  background-color: #f77171 !important;
}