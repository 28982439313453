.table-striped tr:nth-child(odd) {
  background-color: rgb(238, 238, 238, 0.2);
}

@media screen and (min-width: 990px) {
  .Partnerwithustopleftdiv {
    padding: 30px 0px 40px 40px;
  }
  .GetStartedStyle {
    border-radius: 20px !important;
    padding: 75px 0px 0px 40px !important;
    background: linear-gradient(to left, rgb(82, 98, 88, 0.2), #ffffff) !important;
  }
}

@media screen and (max-width: 990px) {
  .Partnerwithustopleftdiv {
    padding: 20px;
  }
  .GetStartedStyle {
    border-radius: 20px !important;
    padding: 75px 30px 75px 30px !important;
    background: linear-gradient(to left, rgb(82, 98, 88, 0.2), #ffffff) !important;
  }
}
