.ratingDrawer {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    /* border-bottom: 1px solid gray; */
}

.Heading-reviews {
    font-size: 20px;
    font-weight: 700;
    color: #222;

}

.ratingCardData {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
}

.rating-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.rating-details-children {
    display: flex;
    gap: 10px;
}

.create-Rating-button{
   text-align: center;
    background: #fff;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 600;
    color: #fb9221;
    line-height: 14px;
    border-radius: 27px;
    border: 1px solid #fb9221;
    padding: 10px;

}