.css-y67w2m-MuiPaper-root-MuiCard-root {
  width: 95vw !important;
  left: 53% !important;
  max-height: 90vh !important;
}
.css-46bh2p-MuiCardContent-root {
  padding-top: 30px !important;
}
.css-uob957-MuiListSubheader-root {
  padding-left: 0px !important;
}
.css-dasnyc-MuiImageListItemBar-title {
  font-size: 1rem !important;
  line-height: 24px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-align: center !important;
  font-weight: 600 !important;
}
