.testimonials {
  background-color: #fff;
  position: relative;
  padding-top: 80px;
}

.testimonials:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 30%;
  background-color: #f8f8f8;
}

#customers-testimonials .item-details {
  background-color: #333;
  color: #fff;
  /* padding: 20px 10px; */
  text-align: left;
}

#customers-testimonials .item-details h5 {
  margin: 0 0 15px;
  font-size: 18px;
  line-height: 18px;
}

#customers-testimonials .item-details h5 span {
  color: #52534e;
  float: right;
  padding-right: 20px;
}

#customers-testimonials .item-details p {
  font-size: 14px;
}

#customers-testimonials .item {
  text-align: center;
  margin-bottom: 80px;
}

.owl-carousel .owl-nav [class*="owl-"] {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.owl-carousel .owl-nav [class*="owl-"].disabled:hover {
  background-color: #d6d6d6;
}

.owl-carousel {
  position: relative;
}

.owl-carousel .owl-next,
.owl-carousel .owl-prev {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 30%;
  font-size: 20px;
  color: #52534e;
  border: 1px solid #ddd;
  text-align: center;
}

/* Desktop */
@media screen and (min-width: 990px) {
  .owl-carousel .owl-prev {
    left: -70px;
  }

  .owl-carousel .owl-next {
    right: -70px;
  }

  .HeaderHeading {
    text-transform: uppercase;
    font-size: 45px;

    margin-bottom: 20px;
  }

  .completedprojectimage {
    height: 80vh;
  }

  .SliderProductImages {
    float: left;
    height: 80vh;
    overflow-y: scroll;
  }

  .SliderProductImages1 {
    width: 100%;
    height: 15vh;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .SliderProductImages2 {
    width: 100%;
    height: auto;
    cursor: pointer;
    margin-top: -7px;
  }

  .SliderProductImages3 {
    float: left;
    margin-top: -7px !important;
  }
}

/* Mobile */
@media screen and (max-width: 990px) {
  .owl-carousel .owl-prev {
    left: -70px;
    display: none;
  }

  .owl-carousel .owl-next {
    display: none;
    right: -70px;
  }

  .HeaderHeading {
    text-transform: uppercase;
    font-size: 20px;

    margin-bottom: 15px;
  }

  .completedprojectimage {
    height: 50vh;
  }

  .SliderProductImages {
    float: left;
    height: 20vh;
    overflow-x: scroll;
    display: flex;
    margin-top: 80px !important;
  }

  .SliderProductImages1 {
    width: 40%;
    height: 16vh;
    cursor: pointer;
    /* margin-bottom: 2px; */
    margin-right: 5px;
  }

  .SliderProductImages2 {
    width: 100vw !important;
    height: 40vh;
    cursor: pointer;
    margin-top: -7px;
  }

  .SliderProductImages3 {
    float: left;
    margin-top: 12px !important;
  }
}

.react-multiple-carousel__arrow {
  z-index: 0;
}

.react-multi-carousel-track li {
  padding: 0 !important;
  /* max-height: 70vh !important; */
}

@media screen and (min-width: 990px) {
  .roomideacarouselstyle {
    height: 80vh;
  }

  .HideInDesktop {
    display: none !important;
  }

  .HomeCarouselImageSectionHeight {
    height: 100vh !important;
  }
}

@media screen and (max-width: 990px) {
  .roomideacarouselstyle {
    height: 50vh;
  }

  .HideInPhone {
    display: none !important;
  }

  .HomeCarouselImageSectionHeight {
    height: 60vh !important;
  }
}

.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px) !important;
  z-index: 1 !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px) !important;
  z-index: 1 !important;
}