.preloaderbody {
    overflow-y: hidden;
      background-color: transparent;
      color: var(--fg);
      font: 1em/1.5 "DM Sans", sans-serif;
      height: 100vh;
      display: grid;
      place-items: center;
      transition:
          background-color var(--trans-dur),
          color var(--trans-dur);
  }
  .preloader {
      text-align: center;
      max-width: 20em;
      width: 100%;
  }
  .preloader__text {
      position: relative;
      height: 1.5em;
  }
  .preloader__msg {
      animation: msg 0.3s 13.7s linear forwards;
      position: absolute;
      width: 100%;
  }
  .preloader__msg--last {
      animation-direction: reverse;
      animation-delay: 14s;
      visibility: hidden;
  }
  .Loadercart {
      display: block;
      margin: 0 auto 1.5em auto;
      width: 5em;
      height: 5em;
  }
  .cart__lines,
  .cart__top,
  .cart__wheel1,
  .cart__wheel2,
  .cart__wheel-stroke {
      animation: cartLines 2s ease-in-out infinite;
  }
  .cart__lines {
      stroke: #475B52;
  }
  .cart__top {
      animation-name: cartTop;
  }
  .cart__wheel1 {
      animation-name: cartWheel1;
      transform: rotate(-0.25turn);
      transform-origin: 43px 111px;
  }
  .cart__wheel2 {
      animation-name: cartWheel2;
      transform: rotate(0.25turn);
      transform-origin: 102px 111px;
  }
  .cart__wheel-stroke {
      animation-name: cartWheelStroke
  }
  .cart__track {
      stroke: hsla(var(--hue),10%,10%,0.1);
      transition: stroke var(--trans-dur);
  }
  
  /* Dark theme */
  @media (prefers-color-scheme: dark) {
      :root {
          --bg: hsl(var(--hue),10%,10%);
          --fg: hsl(var(--hue),10%,90%);
      }
      .cart__track {
          stroke: hsla(var(--hue),10%,90%,0.1);
      }
  }
  
  /* Animations */
  @keyframes msg {
      from {
          opacity: 1;
          visibility: visible;
      }
      99.9% {
          opacity: 0;
          visibility: visible;
      }
      to {
          opacity: 0;
          visibility: hidden;
      }
  }
  @keyframes cartLines {
      from,
      to {
          opacity: 0;
      }
      8%,
      92% {
          opacity: 1;
      }
  }
  @keyframes cartTop {
      from {
          stroke-dashoffset: -338;
      }
      50% {
          stroke-dashoffset: 0;
      }
      to {
          stroke-dashoffset: 338;
      }
  }
  @keyframes cartWheel1 {
      from {
          transform: rotate(-0.25turn);
      }
      to {
          transform: rotate(2.75turn);
      }
  }
  @keyframes cartWheel2 {
      from {
          transform: rotate(0.25turn);
      }
      to {
          transform: rotate(3.25turn);
      }
  }
  @keyframes cartWheelStroke {
      from,
      to {
          stroke-dashoffset: 81.68;
      }
      50% {
          stroke-dashoffset: 40.84;
      }
  }