/* Desktop */
@media screen and (min-width: 990px) {
  .AttributeImage-area {
    /* position: relative;
      height:10vh;
      width:7vw;
      background: #333; */
    margin: 20px;
    position: relative;
    /* height: 10vh; */
    /* width: 7vw; */
    background: #333;
    /* padding: 20px; */
  }
  .AttributeImage-area img {
    max-width: 100%;
    height: auto;
  }
  .AttributeImageRemove-image {
    font-size: 11px;
    display: none;
    position: absolute;
    top: -10px;
    right: 10px;
    border-radius: 10em;
    padding: 3px 5px 1px;
    text-decoration: none;
    /* font: 700 21px/20px sans-serif; */
    background: #555;
    border: 3px solid #fff;
    color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
  }
  .AttributeImageRemove-image:hover {
    background: #e54e4e;
    padding: 3px 5px 1px;
    top: -10px;
    right: 10px;
    color: #fff;
  }
  .AttributeImageRemove-image:active {
    background: #e54e4e;
    top: -10px;
    right: 10px;
  }
  .Attributeimage-area {
    width: 7vw;
  }
}

.inactive {
  background-color: #e54e4e;
}

p > a {
  text-decoration: none;
}

/* Mobile */
@media screen and (max-width: 990px) {
  .AttributeImage-area {
    /* position: relative;
        height:10vh;
        width:7vw;
        background: #333; */
    margin: 20px;
    position: relative;
    /* height: 10vh; */
    /* width: 7vw; */
    background: #333;
    /* padding: 20px; */
  }
  .AttributeImage-area img {
    max-width: 100%;
    height: auto;
  }
  .AttributeImageRemove-image {
    font-size: 11px;
    display: none;
    position: absolute;
    top: -10px;
    /* right: 0; */
    right: 26px;
    border-radius: 10em;
    padding: 3px 5px 1px;
    text-decoration: none;
    /* font: 700 21px/20px sans-serif; */
    background: #555;
    border: 3px solid #fff;
    color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
  }
  .AttributeImageRemove-image:hover {
    background: #e54e4e;
    padding: 3px 5px 1px;
    top: -10px;
    right: 25px;
    color: #fff;
  }
  .AttributeImageRemove-image:active {
    background: #e54e4e;
    top: -10px;
    right: 25px;
  }
  .Attributeimage-area {
    width: 23vw;
  }
}

.PlusBox {
  cursor: pointer;
}
#FileDisplay {
  display: none;
}

#FileDisplay1 {
  display: none;
}

.cursor:hover {
  cursor: all-scroll;
}
