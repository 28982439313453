@import url(https://fonts.googleapis.com/css?family=Roboto:400,500|Sarabun:400,600,700&amp;display=swap);

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
}

.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 calc(1.5rem / 2) 0 0;
}

.navbar-header .dropdown .header-item.show {
  background: rgba(52, 58, 64, 0.05);
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: 240px;
}

.logo {
  line-height: 70px;
}

.logo .logo-sm {
  display: none;
}

.logo-light {
  display: none;
}

.app-search {
  padding: calc(32px / 2) 0;
}

.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 20px;
  padding-right: 40px;
  background-color: #e0e0ea;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 30px;
}

.app-search span {
  position: absolute;
  z-index: 10;
  font-size: 13px;
  line-height: 38px;
  right: 13px;
  top: 0;
  color: #525f80;
}

.megamenu-list li {
  position: relative;
  padding: 5px 0;
}

.megamenu-list li a {
  color: #5b626b;
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
  }

  .logo span.logo-lg {
    display: none;
  }

  .logo span.logo-sm {
    display: inline-block;
  }
}

.page-content {
  padding: 128px calc(1.5rem / 2) 30px calc(1.5rem / 2);
}

.header-item {
  height: 70px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #525f80;
  border: 0;
  border-radius: 0;
}

.header-item:hover {
  color: #525f80;
}

.header-profile-user {
  height: 32px;
  width: 32px;
  background-color: #dee2e6;
}

.logo-dark {
  display: none;
}

.noti-icon i {
  font-size: 24px;
  color: #525f80;
}

.noti-icon .badge {
  position: absolute;
  top: 17px;
  right: 7px;
}

.notification-item .d-flex {
  padding: 0.75rem 1rem;
}

.notification-item .d-flex:hover {
  background-color: #f8f9fa;
}

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: #6c757d;
}

.dropdown-icon-item img {
  height: 24px;
}

.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-icon-item:hover {
  background-color: #f8f9fa;
}

.app-search .AdminSearchbar {
  background-color: rgba(224, 224, 234, 0.07);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.07);
}

.app-search .AdminSearchbar::placeholder {
  font-size: small;
}

@media (max-width: 600px) {
  .navbar-header .dropdown {
    position: static;
  }

  .navbar-header .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}

.topnav {
  background: #333547;
  padding: 0 calc(1.5rem / 2);
  -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, 0.44);
  box-shadow: 0 0 13px 0 rgba(236, 236, 241, 0.44);
  /* margin-top: 70px; */
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
}

@media (max-width: 991.98px) {
  .topnav {
    background: #fff;
  }
}

.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}

.topnav .navbar-nav .nav-link {
  font-size: 14.5px;
  position: relative;
  padding: 1rem 1.3rem;
  color: #fff;
}

.topnav .navbar-nav .nav-link i {
  font-size: 15px;
  display: inline-block;
}

.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover {
  color: #ddd;
  background-color: transparent;
}

@media (max-width: 991.98px) {
  .topnav .navbar-nav .nav-link:focus,
  .topnav .navbar-nav .nav-link:hover {
    color: #174379;
  }
}

@media (max-width: 991.98px) {
  .topnav .navbar-nav .nav-link {
    color: #525f80;
  }
}

.topnav .navbar-nav > .dropdown.active > a {
  color: #174379 !important;
}

@media (max-width: 991.98px) {
  .topnav .navbar-nav > .dropdown.active > a {
    color: #174379 !important;
  }
}

.topnav .navbar-nav .dropdown-item {
  color: #66676d;
}

.topnav .navbar-nav .dropdown-item.active,
.topnav .navbar-nav .dropdown-item:hover {
  color: #174379;
  background-color: transparent;
}

.topnav .navbar-nav .nav-item .nav-link.active {
  color: #174379;
}

@media (max-width: 991.98px) {
  .topnav .navbar-nav .nav-item .nav-link.active {
    color: #174379 !important;
  }
}

.topnav .navbar-nav .dropdown.active > a {
  color: #174379;
  background-color: transparent;
}

@media (min-width: 992px) {
  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }

  .topnav .dropdown-item {
    padding: 0.5rem 1.5rem;
    min-width: 180px;
  }

  .topnav .dropdown.mega-dropdown .mega-dropdown-menu {
    left: 0;
    right: auto;
  }

  .topnav .dropdown .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .topnav .dropdown .dropdown-menu .arrow-down::after {
    right: 15px;
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%);
    position: absolute;
  }

  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
  }

  .topnav .dropdown:hover > .dropdown-menu {
    display: block;
    background: #475b52;
  }
  .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .navbar-toggle {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
    right: 100%;
    left: auto;
  }
}

@media (max-width: 991.98px) {
  .topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0;
  }

  .topnav .navbar-nav .nav-link {
    padding: 0.75rem 1.1rem;
  }

  .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 15px;
  }

  .topnav .dropdown .dropdown-menu.mega-dropdown-menu {
    width: auto;
  }

  .topnav .dropdown .dropdown-menu.mega-dropdown-menu .row {
    margin: 0;
  }

  .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent;
  }

  .topnav .dropdown .dropdown-item.active,
  .topnav .dropdown .dropdown-item:active {
    color: #174379;
  }

  .topnav .arrow-down::after {
    right: 15px;
    position: absolute;
  }
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 990px) {
  .HideInDesktop {
    display: none;
  }
}

@media screen and (max-width: 990px) {
  .HideInPhone {
    display: none;
  }
}
