figure.effect-zoe:hover figcaption,
figure.effect-zoe:hover h2,
figure.effect-zoe:hover p.icon-links a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

figure.effect-zoe .SingleProductfigcaption {
  visibility: hidden;
  top: auto;
  bottom: 0;
  padding: 1em;
  height: 3.75em;
  background: #475B52;
  color: #fff;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

/* Desktop */
@media screen and (min-width: 990px) {
  .ProductDetailAddtocartStyle {
    width: 115%;
    margin-bottom: 10px;
  }
  .FreeShipping {
    float: left;
    height: 50px;
    background-position: center center;
    background-size: cover;
  }
  .POSectionStyle1 {
    float: left;
    height: 50px;
    background-position: center center;
    background-size: cover;
  }
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; /* Adjust the max-width as needed */
}


.Breadcrumbs-test{
  line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
    color: #828bb2;
    font-family: "Work Sans", sans-serif;
    font-weight: 300;
}
/* Mobile */
@media screen and (max-width: 990px) {
  .ProductDetailAddtocartStyle {
    width: 50%;
    margin-bottom: 10px;
  }
  .FreeShipping {
    float: left;
    height: 50px;
    background-position: center center;
    background-size: cover;
    width: 14vw;
  }
  .MobileFreeShipping {
    width: 50%;
    float: left;
  }
  .POSectionStyle1 {
    float: left;
    height: 43px;
    background-position: center center;
    background-size: cover;
  }
}

/* Desktop */
@media screen and (min-width: 990px) {
  .productimagesizecolour-area {
    /* position: relative;
  height:10vh;
  width:7vw;
  background: #333; */
    margin: 20px;
    position: relative;
    /* height: 10vh; */
    /* width: 7vw; */
    background: #333;
    /* padding: 20px; */
  }
  .productimagesizecolour-area img {
    max-width: 100%;
    height: auto;
  }
  .productremovesizecolour-image {
    font-size: 11px;
    display: none;
    position: absolute;
    top: -10px;
    right: 0px;
    border-radius: 10em;
    padding: 3px 5px 1px;
    text-decoration: none;
    /* font: 700 21px/20px sans-serif; */
    background: #555;
    border: 3px solid #fff;
    color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
  }
  .productremovesizecolour-image:hover {
    background: #e54e4e;
    padding: 3px 5px 1px;
    top: -10px;
    right: 25px;
    color: #fff;
  }
  .productremovesizecolour-image:active {
    background: #e54e4e;
    top: -10px;
    right: 25px;
  }
  .productDetailimage-area {
    width: 7vw;
  }
}

/* Mobile */
@media screen and (max-width: 990px) {
  .productimagesizecolour-area {
    /* position: relative;
    height:10vh;
    width:7vw;
    background: #333; */
    margin: 20px;
    position: relative;
    /* height: 10vh; */
    /* width: 7vw; */
    background: #333;
    /* padding: 20px; */
  }
  .productimagesizecolour-area img {
    max-width: 100%;
    height: auto;
  }
  .productremovesizecolour-image {
    font-size: 11px;
    display: none;
    position: absolute;
    top: -10px;
    right: 0px;
    border-radius: 10em;
    padding: 3px 5px 1px;
    text-decoration: none;
    /* font: 700 21px/20px sans-serif; */
    background: #555;
    border: 3px solid #fff;
    color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
  }
  .productremovesizecolour-image:hover {
    background: #e54e4e;
    padding: 3px 5px 1px;
    top: -10px;
    right: 25px;
    color: #fff;
  }
  .productremovesizecolour-image:active {
    background: #e54e4e;
    top: -10px;
    right: 25px;
  }
  .productDetailimage-area {
    width: 23vw;
  }
}

.buttongroupstyling {
  border-color: #475b52;
  color: #475b52;
}

.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #475B52;
  border-color: #475B52;
}

.buttongroupstyling:hover {
  border-color: #475b52;
  background-color: #475b52;
  color: #fff;
}

@media screen and (max-width: 990px) {
  .ProductDetailsAttributeDivMobileView {
    float: left;
    width: 23vw;
  }

  .ProductDetailsAttributeImageMobileView img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
  .image-area img {
    max-width: 100%;
    height: 40px;
  }

  .Attributeimage-area {
    width: 15vw;
  }
}
@media screen and (min-width: 990px) {
  .Attributeimage-area {
    width: 15vw;
  }

  .ProductDetailsAttributeDivMobileView {
    float: left;
    width: 5vw;
  }
}

.mainimage {
  background-position: center;
  background-size: cover;
  height: 50vh;
  width: 47%;
  margin: 10px;
}

@media screen and (max-width: 990px) {
  .mainimage {
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: 95%;
  }
}

.customizedimage {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px;
  overflow: hidden;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: var(--bs-btn-active-color) !important;
  background-color: #475B52 !important;
  border-color: #475B52 !important;
}
/* .btn{
  color: #475B52 !important;
  border: var(--bs-btn-border-width) solid #475B52 !important;
} */

@media screen and (max-width: 990px) {
  .customizedimage {
    position: relative;
    display: flex;
    justify-content: center;
    width: 96vw;
    margin: 10px;
    overflow: hidden;
    height: 40vh !important;
  }

  .mobileproductdetailsTopdiv {
    margin-top: -15vh !important;
  }
}


.shareOpen{
  display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid gray;
    /* width: 100%; */
    width: fit-content;
    padding: 8px;
    border-radius: 5px;
}


.shareOpen1{
  display: flex;
    gap: 5px;
    align-items: center;
    margin-top: -20px;
    cursor: pointer;
    /* border: 1px solid gray; */
    /* width: 100%; */
    width: fit-content;
    padding: 8px;
    border-radius: 5px;
    font-size: 22px;
}

