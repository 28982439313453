@media screen and (min-width: 990px) {
  .CompletedProjectpagestyle {
    padding: 20px;
  }
}

@media screen and (max-width: 990px) {
  .CompletedProjectpagestyle {
    padding: 0px;
  }
}
