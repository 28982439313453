/* Desktop */
@media screen and (min-width: 990px) {
  .querysectionstyle {
    padding: 10px 70px 0px 70px;
  }
  .mapstyle {
    width: 500px;
    height: 400px;
    border: 0;
  }
  .querysectionstyle1 {
    float: left;
  }
  .TopDiv {
    height: 25vh;
  }
  .contactinfodiv {
    float: left;
    padding: 0px 50px 0px 0px;
  }
}

/* Mobile */
@media screen and (max-width: 990px) {
  .querysectionstyle {
    padding: 10px 0px 0px 0px;
  }
  .mapstyle {
    width: 142%;
    height: 250px;
    border: 0;
  }
  .querysectionstyle1 {
    float: left;
    width: 100%;
  }
  .TopDiv {
    height: 10vh;
  }
  .ContactTopSection {
    margin-top: -16vh;
  }
  .contactinfodiv {
    float: left;
    padding: 0px 20px 20px 20px;
  }
}
