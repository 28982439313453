.sub-channel-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 24px;
  /* justify-content: center; */
}
.sub-channel-info {
  /* width: calc(18% - 12.8px); */
  /* margin: 0 16px 16px 0; */
  background: #fff;
  overflow: hidden;
  cursor: pointer;
}
.sub-channel-info:hover {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.09);
}
.img-box {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  overflow: hidden;
}
.sub-channel-title {
  font-size: 16px;
  color: #222;
  text-align: center;
  margin: 12px 20px;
  line-height: 20px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  color: #222;
  line-height: 32px;
}

.sub-channel-info {
  position: relative;
  overflow: hidden;
  display: block;
  text-decoration: none;
  /* border-radius: 10px; */
}

.img-box {
  position: relative;
  overflow: hidden;
}

.card-img-top {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease-out; /* You can adjust the duration and timing function */
}

.img-box:hover .card-img-top {
  transform: scale(1.1); /* You can adjust the scaling factor */
}

.sub-channel-info-img {
  /* border-radius: 10px; */
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease-out;
  height: 50vh;
}

.img-box:hover .sub-channel-info-img {
  transform: scale(1.1); /* You can adjust the scaling factor */
}

.sub-channel-title {
  /* margin-top: 10px; */
  text-align: center;
  font-size: 16px;
  color: #333;
}

@media screen and (min-width: 990px) {
  .exclusivedesignstyle {
    width: 33.3vw !important;
    margin-left: 5px;
    margin-top: 10px;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 990px) {
  .exclusivedesignstyle {
    width: 100vw !important;
    margin-left: 12px;
    margin-top: 10px;
    padding-right: 0px !important;
    /* padding-left: 0px !important; */
  }
}
