@media screen and (min-width: 990px) {
  .singleexperiencecenterimagestyle {
    width: 95%;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    height: 50vh;
  }

  .Singleexperiencecenterdiv {
    text-align: center;
    float: left;
    padding: 20px 70px 20px 70px;
  }

  .Singleexperiencecentername {
    text-align: center;
    color: #000;
    font-size: 35px;
    text-transform: uppercase;

  }

  .Singleexperiencecenterheading {
    text-align: center;
    font-weight: 600;
    letter-spacing: 2px;
    color: #475b52;
    font-size: 40px;
  }
}

@media screen and (max-width: 990px) {
  .singleexperiencecenterimagestyle {
    width: 90vw;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    height: 50vh;
  }

  .Singleexperiencecenterdiv {
    text-align: center;
    float: left;
    padding: 0px;
  }

  .Singleexperiencecentername {
    text-align: center;
    color: #000;
    font-size: 20px;
    text-transform: uppercase;

  }

  .Singleexperiencecenterheading {
    text-align: center;
    font-weight: 600;
    letter-spacing: 2px;
    color: #475b52;
    font-size: 25px;
  }
}