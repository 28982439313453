@media screen and (min-width: 990px) {
  .experiencecenterstyle {
    text-align: center;
    float: left;
    padding: 40px 50px 20px 50px;
    background-size: cover;
    background-position: center;
  }

  .experiencecentername {
    text-align: center;
    color: #000;
    font-size: 30px;
    text-transform: uppercase;

  }

  .experiencecenter {
    text-align: center;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 40px;
    color: #475b52;
  }
}

@media screen and (max-width: 990px) {
  .experiencecenterstyle {
    text-align: center;
    float: left;
    padding: 20px;
    background-size: cover;
    background-position: center;
  }

  .experiencecentername {
    text-align: center;
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;

  }

  .experiencecenter {
    text-align: center;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 25px;
    color: #475b52;
  }
}