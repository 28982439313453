.table-striped {
  width: 100%;
  border-collapse: collapse;
}

.table-striped td {
  border: 1px dashed #ddd;
  padding: 8px;
}

.table-striped tr:nth-child(even) {
  background-color: #fff;
}

.table-striped tr:hover {
  background-color: #fff;
}

.applyButton {
  color: "#fff";
  background-color: "#475B52";
  border: "none";
  padding: "5px 10px";
  border-radius: "5px";
  font-size: "12px";
}

.disabledButton {}