       
.image-area {
  position: relative;
  /* height:15vh; */
  width:14vw;
  /* background: #ddd; */
  
}
.image-area img{
  max-width: 100%;
  height: 40px;
  /* height: auto; */
}
.remove-image {
display: none;
position: absolute;
top: 190px;
right: 70px;
border-radius: 10em;
padding: 2px 6px 3px;
text-decoration: none;
/* font: 700 21px/20px sans-serif; */
background: #555;
border: 3px solid #fff;
color: #FFF;
box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 2px 4px rgba(0,0,0,0.3);
  text-shadow: 0 1px 2px rgba(0,0,0,0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
 background: #E54E4E;
  padding: 3px 7px 5px;
  top: 190px;
right: 70px;
color: #fff;
}
.remove-image:active {
 background: #E54E4E;
  top: 190px;
right: 70px;
}

.Copy-image {
display: none;
position: absolute;
top: 190px;
right: 100px;
border-radius: 10em;
padding: 2px 6px 3px;
text-decoration: none;
/* font: 700 21px/20px sans-serif; */
background: #555;
border: 3px solid #fff;
color: #FFF;
box-shadow: 0 2px 6px rgba(0,0,0,0.5), inset 0 2px 4px rgba(0,0,0,0.3);
  text-shadow: 0 1px 2px rgba(0,0,0,0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.Copy-image:hover {
 background: #008AD0;
  padding: 3px 7px 5px;
  top: 190px;
right: 100px;
color: #fff;
}
.Copy-image:active {
 background: #008AD0;
  top: 190px;
right: 100px;
}